<template>
    <div class="common-layout" v-loading.fullscreen="page_data_loading">
        <el-row :gutter="20" class="mt-2">
            <el-col :span="13">
                <TableList :columns="channelSummaryModel.columns" :data="dataSummaryModel"
                    :totalData="dataSummaryModel_total" :title="channelSummaryModel.headerTitle" :tableIndex="1" />
            </el-col>
            <el-col :span="11">
                <TableList :columns="stockByModel.columns" :data="dataStockByModel" :totalData="dataStockByModel_total"
                    :title="stockByModel.headerTitle" :tableIndex="2" />
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mt-3">
            <el-col :span="24">
                <TableList :columns="skuByCategory.columns" :height="800" :hasChildTable="true"
                    :data="dataSkuByCategory" :totalData="dataSkuByCategory_total" :title="skuByCategory.headerTitle"
                    :tableIndex="3" />
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mt-3">
            <el-col :span="24">
                <TableList :columns="sellerCostByModel.columns" :height="800" :hasChildTable="true"
                    :data="dataSellerCostByModel" :title="sellerCostByModel.headerTitle" :tableIndex="4"
                    :tableModelFilter="true" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {
    NumericTothreeDigit,
    isEmpty,
    isNotEmpty,
    cloneDeepObject,
} from "@/utils/util.js";
import { ACCESS_TOKEN, LOGIN_IN_USERNAME } from "@/store/mutation-types";

import { reactive, ref, toRefs, watch, computed, onMounted, onActivated } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
    getCatalogReport,
    initCatalogPage
} from "@/api/dashboard";
import { logout } from "@/api/login";
import { ElMessageBox, ElMessage } from "element-plus";
import TableList from "../components/TableList.vue";
import { UserOutlined } from "@ant-design/icons-vue";
import {
    channelSummaryModel, skuByCategory, stockByModel, sellerCostByModel
} from "@/config/catalogTableColumns.js";
import moment from "moment";

export default {
    name: "index",
    components: {
        UserOutlined,
        TableList,
    },
    mounted() {
        var _self = this;
        window.onresize = function () {
            if (typeof _self.sales_map_echart != "undefined") {
                setTimeout(function () {
                    _self.sales_map_echart.resize();
                }, 200);
            }
        };
    },
    setup() {
        var sales_map_echart;

        const barItemRefs = ref(null);

        let c_skuByCategory = cloneDeepObject(skuByCategory)
        let c_stockByModel = cloneDeepObject(stockByModel)
        let c_sellerCostByModel = cloneDeepObject(sellerCostByModel)

        const my_setup = reactive({
            collapsed: false,
            page_data_loading: false,
            initedDatePicker: false,
            show_order_type: true,
            can_change_billing_to_cid: true,

            channelSummaryModel: channelSummaryModel,

            skuByCategory: skuByCategory,
            stockByModel: stockByModel,
            sellerCostByModel: sellerCostByModel,

            dataSummaryModel: [],
            dataSummaryModel_total: [],
            dataSkuByCategory: [],
            dataSkuByCategory_total: [],
            dataStockByModel: [],
            dataStockByModel_total: [],
            dataSellerCostByModel: [],
            datasellerCostByModel_total: [],
        });

        const shortcuts = [{
            text: 'Today',
            value: new Date(),
        },
        {
            text: 'Yesterday',
            value: () => {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                return date
            },
        },
        {
            text: 'A week ago',
            value: () => {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                return date
            },
        },
        ]
        const router = useRouter();
        const route = useRoute();
        const username = sessionStorage.getItem(LOGIN_IN_USERNAME);
        //监听路由    
        watch(
            () => route,
            (n, o) => {
                //console.log("new route", n);
                //console.log("old route", o);
            },
            { immediate: false }
        );

        watch(
            () => route.name,
            (n, o) => {
                if (n !== o && n == "catalog_report") {
                    //loadPageData();
                }
            },
            { immediate: false }
        );
        watch(
            () => route.query.id,
            (n, o) => {
                let route_name = route.name;
                if (n !== o && route_name == "catalog_report") {
                    //loadPageData();
                }
            },
            { immediate: false }
        );

        onMounted(() => {
            loadPageData();
        })
        onActivated(() => {
            const reloadPage = route.meta.reloadPage
            if (reloadPage === true) {
                loadPageData()
            }
        })
        async function initPage() {
            const serial_no = route.query.id;
            let params = { serial_no }

            await initCatalogPage(params).then((res) => {
                let catalog_cids = res.data.billing_cids

                let ctalog_cids = []
                catalog_cids.forEach((item) => {
                    let catalog_contact_id = item.catalog_contact_id

                    if (isNotEmpty(catalog_contact_id)) {
                        ctalog_cids = _.concat(ctalog_cids, catalog_contact_id.split(','))
                    }
                });

                my_setup.skuByCategory.columns.splice(0)
                my_setup.skuByCategory.columns = _.concat(my_setup.skuByCategory.columns, c_skuByCategory.columns)

                my_setup.sellerCostByModel.columns.splice(0)
                my_setup.sellerCostByModel.columns = _.concat(my_setup.sellerCostByModel.columns, c_sellerCostByModel.columns)

                let sku_columns = my_setup.skuByCategory.columns
                let sellerCost_columns = my_setup.sellerCostByModel.columns
                if (ctalog_cids.length > 0) {
                    sellerCost_columns.push({
                        "label": "Freight Type",
                        "column": "freight_class",
                        "key": "freight_class",
                        "dataStyle": "text",
                        "width": '140px',
                    })

                    ctalog_cids.forEach(item => {
                        let min_width = 17 * (item.length - 1)
                        sku_columns.push({
                            "label": item,
                            "column": item,
                            "childTable": [
                                {
                                    "label": 'SKUs',
                                    "column": item,
                                    "key": item,
                                    "dataStyle": "clickLink",
                                    "min_width": '80px'
                                }, {
                                    "label": 'G',
                                    "column": item + '_G',
                                    "key": item + '_G',
                                    "dataStyle": "clickLink",
                                    "min_width": '80px'
                                }, {
                                    "label": 'G1',
                                    "column": item + '_G1',
                                    "key": item + '_G1',
                                    "dataStyle": "clickLink",
                                    "min_width": '80px'
                                }, {
                                    "label": 'G2',
                                    "column": item + '_G2',
                                    "key": item + '_G2',
                                    "dataStyle": "clickLink",
                                    "min_width": '80px'
                                }, {
                                    "label": 'F',
                                    "column": item + '_F',
                                    "key": item + '_F',
                                    "dataStyle": "clickLink",
                                    "min_width": '80px'
                                }
                            ],
                            "min_width": '400px'
                        })

                        sellerCost_columns.push({
                            "label": item,
                            "childTable": [
                                {
                                    "label": 'Sell Name',
                                    "column": item + '_sell_name',
                                    "key": item + '_sell_name',
                                    "dataStyle": "clickLink",
                                    "c_id": item,
                                    "min_width": '140px'
                                }, {
                                    "label": 'Dropship',
                                    "column": item + '_cost_dropship',
                                    "key": item + '_cost_dropship',
                                    "dataStyle": "money",
                                    "min_width": '100px'
                                }, {
                                    "label": 'Vendor',
                                    "column": item + '_cost_vendor',
                                    "key": item + '_cost_vendor',
                                    "dataStyle": "money",
                                    "min_width": '100px'
                                }, {
                                    "label": 'Direct:FCA',
                                    "column": item + '_cost_direct_fca',
                                    "key": item + '_cost_direct_fca',
                                    "dataStyle": "money",
                                    "min_width": '120px'
                                }, {
                                    "label": 'Direct:FOB',
                                    "column": item + '_cost_direct_fob',
                                    "key": item + '_cost_direct_fob',
                                    "dataStyle": "money",
                                    "min_width": '120px'
                                }
                            ],
                            "min_width": '580px'
                        })
                    })

                    sku_columns.push({
                        "label": "Total",
                        "column": "total",
                        "key": "total",
                        "dataStyle": "integer",
                        "width": '110px',
                        "class_name": "totalClass"
                    })
                }
            })
        }
        function loadPageData() {
            my_setup.page_data_loading = true;
            const serial_no = route.query.id;
            let params = { serial_no }

            //init data
            initPage().then(() => {
                getCatalogReport(params)
                    .then((res) => {
                        let catalog_data = res.data.catalog_data

                        my_setup.dataSummaryModel = catalog_data.channel_summary;
                        my_setup.dataSummaryModel_total = catalog_data.channel_summary_total;
                        my_setup.dataSkuByCategory = catalog_data.sku_by_category;
                        my_setup.dataSkuByCategory_total = catalog_data.sku_by_category_total;
                        my_setup.dataStockByModel = catalog_data.stock_by_model;
                        my_setup.dataStockByModel_total = catalog_data.stock_by_model_total;
                        my_setup.dataSellerCostByModel = catalog_data.seller_cost_by_model;
                        my_setup.datasellerCostByModel_total = catalog_data.margin_at_billto_total;
                    })
                    .then(() => {
                    })
                    .catch((err) => console.log(err))
                    .finally(() => {
                        my_setup.page_data_loading = false;
                    });
            })
        }

        return {
            shortcuts,
            barItemRefs,
            ...toRefs(my_setup),
            initPage,
            loadPageData,
            username,
        };
    },
};
</script>

<style scoped>
:deep(.el-table__footer .el-table__cell .cell) {
    color: #000000;
    font-weight: 600;
}

:deep(.el-main) {
    overflow: hidden;
}

.menu {
    border-right: 1px solid #f0f0f0;
    overflow: hidden;
}

.text-center,
[text~="center"] {
    text-align: center;
}

.text-end,
[text~="end"] {
    text-align: end;
}

.flex {
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box {
    background: #fcf2ed;
    width: 200px;
    height: 150px;
    border-radius: 10px;
    margin: auto;
    position: relative;
}

.homelogo {
    position: absolute;
    top: -25px;
    left: 35px;
}

.emailtext {
    font-size: 12px;
    padding-top: 60px;
    text-align: center;
}

.logout {
    width: 70%;
    background: #e8c2a8;
    border-radius: 8px;
    border-color: #e8c2a8;
    margin-top: 10px;
    font-weight: bold;
    color: #422e21;
}

.header {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 5px 8px 0px #f2f2f2;
}

.name {
    font-size: 18px;
    margin: 0 10px;
}

.Table {
    margin: 2%;
}

.home_head {
    display: flex;
    justify-content: space-between;
}

.datePicker {
    border: 1px solid #a3a3a3;
    border-radius: 5px;
}

.Order {
    color: #4a4a4a;
}

.contentTable {
    margin-top: 20px;
}

.flex2 {
    display: flex;
    justify-content: space-between;
}

.sales-map-box {
    height: 75vh;
    margin-bottom: 150px;
}

.sales_map_bar_box {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.sales_map_bar_box::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
    scrollbar-arrow-color: red;
}

.sales_map_bar_box::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
    scrollbar-arrow-color: red;
}

.sales_map_bar_box::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.sales_map_bar_box ul {
    height: 75vh;
}

.sales_map_bar_item {
    width: 100%;
    margin-top: 15px;
    color: #000000;
}

.sales_map_bar_item_all {
    width: 100%;
    height: 15px;
    background-color: #f2f2f2;
}

.sales_map_bar_item_tv {
    height: 100%;
    height: 15px;
}
</style>