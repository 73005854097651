const channelSummaryModel = {
    headerTitle: "Channel Catalog Summary",
    columns: [
        {
            "label": "Contact ID",
            "column": "contact_id",
            "key": "contact_id",
            "dataStyle": "text",
            "width":"120px"
        },
        {
            "label": "ERP Models",
            "column": "model_names",
            "key": "model_names",
            "dataStyle": "integer",
            "width":"120px"
        },
        {
            "label": "Sell Names",
            "column": "sell_names",
            "key": "sell_names",
            "dataStyle": "integer",
            "width":"120px"
        },
        {
            "label": "InStock%",
            "column": "instock_names_percent",
            "key": "instock_names_percent",
            "dataStyle": "percent",
            "width":"120px"
        },
        {
            "label": "Setting InStock%",
            "column": "edi_qty_gt0_percent",
            "key": "edi_qty_gt0_percent",
            "dataStyle": "percent",
            "width":"150px"
        },
        {
            "label": "Setting Method",
            "column": "edi_setting_method",
            "key": "edi_setting_method",
            "dataStyle": "text",
            "width":"140px"
        },
        {
            "label": "Setting QTY",
            "column": "edi_setting_qty",
            "key": "edi_setting_qty",
            "dataStyle": "text",
            "width":"120px"
        }
    ]
}

const skuByCategory = {
    headerTitle: "SKUs by Channels and Category",
    columns: [
        {
            "label": "Categories",
            "column": "categories",
            "key": "categories",
            "dataStyle": "text",
            "fixed": true,
            "width": '130px'
        }
    ]
}
const sellerCostByModel = {
    headerTitle: "Seller SKU And Wholesale Cost",
    columns: [
        {
            "label": "Model",
            "column": "model",
            "key": "model",
            "dataStyle": "text",
            "fixed": true,
            "width": '150px',
            "hasChildTable": false
        }
    ]
}

const stockByModel = {
    headerTitle: "Out of Stock ERP Models",
    columns: [
        {
            "label": "Model",
            "column": "model",
            "key": "model",
            "dataStyle": "text",
            "width": '120px'
        },
        {
            "label": "Oversold QTY",
            "column": "current_can_sell",
            "key": "current_can_sell",
            "dataStyle": "integer",
            "width": '140px'
        },
        {
            "label": "SOH",
            "column": "onhand_qty",
            "key": "onhand_qty",
            "dataStyle": "integer",
            "width": '100px'
        },
        {
            "label": "Status",
            "column": "additional_description",
            "key": "additional_description",
            "dataStyle": "text",
            "width": "160px"
        },
        {
            "label": "PO Date",
            "column": "po_date",
            "key": "po_date",
            "dataStyle": "text",
        },
        {
            "label": "PO QTY",
            "column": "po_qty",
            "key": "po_qty",
            "dataStyle": "integer",
        }
    ]
}

export { channelSummaryModel, skuByCategory, stockByModel, sellerCostByModel }